/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { VscOutput } from "react-icons/vsc";
import { HiDatabase } from "react-icons/hi";
import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "Logs",
    icon: VscOutput,
    ID: dashboardLayoutRoutes.logsPage.ID,
    path: dashboardLayoutRoutes.logsPage.path,
  },
  {
    name: "Log Reports",
    icon: HiDatabase,
    ID: dashboardLayoutRoutes.logsRequest.ID,
    path: dashboardLayoutRoutes.logsRequest.path,
  },
];

export default routes;
