import React, { useState, useEffect, Fragment } from "react";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import pages from "../constants/pages";
import { signInMember } from "../redux/slices/memberSlice";
import GlobalStyle from "../components/GlobalStyle";
import WebsiteLoader from "./dashboard layout components/WebsiteLoader";
import MainContent from "./dashboard layout components/MainContent";
import Navbar from "./dashboard layout components/Navbar";
import Sidebar from "./dashboard layout components/Sidebar";
import Footer from "./dashboard layout components/Footer";

const ContentWrapper = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;

const MainContentWrapper = styled.div`
  flex: 1;
  margin: 4px;
  overflow: auto;

  display: flex;
  flex-direction: column;
`;

export default function DashboardLayout() {
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const dispatch = useDispatch();

  const [sidebarState, setSidebarState] = useState({
    static: false,
    open: false,
  });

  const { authToken } = useSelector((state) => state.memberDetails);
  const localStorageToken = localStorage.getItem("authToken");
  const sessionStorageToken = sessionStorage.getItem("authToken");

  useEffect(() => {
    if (!authToken && (localStorageToken || sessionStorageToken)) {
      dispatch(
        signInMember({
          authToken: localStorageToken || sessionStorageToken,
          rememberMe: !!localStorageToken,
        })
      );
    }
  }, [authToken, dispatch, localStorageToken, sessionStorageToken]);

  if (!(authToken || localStorageToken || sessionStorageToken)) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  if (!authToken && (localStorageToken || sessionStorageToken)) {
    return (
      <Fragment>
        <CssBaseline />
        <GlobalStyle />

        <WebsiteLoader />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <Navbar sidebarState={sidebarState} toggleSideBar={toggleSidebarState} />

      <ContentWrapper>
        <Sidebar
          sidebarState={sidebarState}
          onMouseEnter={mouseEnterSidebar}
          onMouseLeave={mouseLeaveSidebar}
        />

        <MainContentWrapper>
          <MainContent />

          <Footer />
        </MainContentWrapper>
      </ContentWrapper>
    </Fragment>
  );

  function toggleSidebarState() {
    setSidebarState((prevValue) => ({
      static: !prevValue.static,
      open: !prevValue.open,
    }));
  }

  function mouseEnterSidebar() {
    if (isLaptop && !sidebarState.static) {
      setSidebarState((prevValue) => ({
        ...prevValue,
        open: true,
      }));
    }
  }

  function mouseLeaveSidebar() {
    if (isLaptop && !sidebarState.static) {
      setSidebarState((prevValue) => ({
        ...prevValue,
        open: false,
      }));
    }
  }
}
