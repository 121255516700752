import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Table from "./Table";

export default function Logs() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [logs, setLogs] = useState([]);
  const [apiList, setApiList] = useState([]);

  var [filterData, setFilterData] = useState({
    log_id: "",
    status: "",
    start_time: "",
    end_time: "",
    api_name: "",
  });

  useEffect(() => {
    loader.start(2);
    axios({
      url: "/?start=0&length=26",
      method: "GET",
      data: {},
    }).then((response) => {
      if (response.status) {
        setLogs(response);
        loader.apiComplete();
      }
    });

    axios({
      url: "/api-name",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setApiList(response.data);
        loader.apiComplete();
      }
    });
  }, [dispatch, axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Logs list" />

      <Table
        logs={logs}
        setLogs={setLogs}
        apiList={apiList}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </Loader>
  );
}
