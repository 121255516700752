import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import signIn from "./helper functions/signIn";
import signOut from "./helper functions/signOut";

export const signInMember = createAsyncThunk("member/signIn", signIn);

export const signOutMember = createAsyncThunk("member/signOutMember", signOut);

const memberInitialState = {
  ID: "",
  authToken: "",
};

export const memberSlice = createSlice({
  name: "member",
  initialState: memberInitialState,
  reducers: {
    setActiveBot: (state, { payload }) => {
      localStorage.setItem("activeBot", payload.ID);

      state.activeBot = payload;
    },

    deleteAccessToken: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return memberInitialState;
    },
  },

  extraReducers: {
    [signInMember.fulfilled]: (_, { payload }) => {
      return payload;
    },

    [signInMember.rejected]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return { ...memberInitialState, authToken: "" };
    },

    [signOutMember.fulfilled]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return memberInitialState;
    },
  },
});

export const { setActiveBot, deleteAccessToken } = memberSlice.actions;

export default memberSlice.reducer;
