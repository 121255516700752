/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import { Pages } from "@mui/icons-material";
import pages from "../../constants/pages";
import Logs from "../../pages/logs components/Logs";
import LogsRequest from "../../pages/logs request components/LogsRequestList";
// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

//Main Module Components

const dashboardLayoutRoutes = {
  logsPage: {
    ID: "LOGS",
    path: "/logs",
    element: Logs,
    index: true,
  },
  logsRequest: {
    ID: "logsRequest",
    path: "/logs-request",
    element: LogsRequest,
    index: true,
  },
};

export default dashboardLayoutRoutes;
