import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";

import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Table from "./logs request table components/Table";
import pages from "../logs request components/logs request table components/DownloadCSVDialog";
import DownloadCSVDialog from "./logs request table components/DownloadCSVDialog";

export default function LogsRequest() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [apiList, setApiList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logsRequest, setLogsRequest] = useState([]);
  const [generateLogId, setGenerateLogId] = useState("");

  function handleClickOpen() {
    setDialogOpen(true);
  }

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/api-name",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setApiList(response.data);
        loader.apiComplete();
      }
    });
  }, [dispatch, axios, loader]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/request-log-list",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setLogsRequest(response.data);
        loader.apiComplete();
      }
    });
  }, [dispatch, axios, loader, generateLogId]);

  return (
    <Loader>
      <PageHeader pageName="Log Reports" buttons={[<CreateButton />]} />
      <Table
        logsRequest={logsRequest}
        apiList={apiList}
        setApiList={setApiList}
        generateLogId={generateLogId}
        setGenerateLogId={setGenerateLogId}
      />
      <DownloadCSVDialog
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        apiList={apiList}
        setGenerateLogId={setGenerateLogId}
      />
    </Loader>
  );
  function CreateButton() {
    return (
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Request Log
      </Button>
    );
  }
}
