import React from "react";

import { useAxios } from "../../hooks";
import { convertDate } from "../../utils";
import DataTable from "../../components/DataTable";
import { Chip } from "../../components";

export default function Table({ logs, setLogs, filterData }) {
  const axios = useAxios();

  const columns = [
    // {
    //   name: "user_id",
    //   label: "User ID",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value ? value : "Not Available";
    //     },
    //   },
    // },
    {
      name: "log_id",
      label: "Log ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "api_name",
      label: "API Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "log_timestamp",
      label: "Log Time",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    // {
    //   name: "ovd_number",
    //   label: "OVD Number",
    //   options: {
    //     customBodyRender: (value) => {
    //       return value ? value : "Not Available";
    //     },
    //   },
    // },

    {
      name: "status",
      label: "API Status",
      options: {
        customBodyRender: (value) => {
          return value ? (
            value === "success" ? (
              <Chip type="success" label="Success" />
            ) : (
              <Chip type="error" label="Failed" />
            )
          ) : (
            "Not Available"
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: 25,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    count: logs.total_count,
    rowsPerPageOptions: [5, 10, 15, 20, 25],

    onSearchChange(searchText) {
      if (searchText) {
        axios({
          url: "/?start=0&length=26&search=" + searchText,
          method: "GET",
          data: {},
        }).then((response) => {
          if (response.status) {
            setLogs(response);
          }
        });
      } else {
        axios({
          url: "/?start=0&length=26",
          method: "GET",
          data: {},
        }).then((response) => {
          if (response.status) {
            setLogs(response);
          }
        });
      }
    },

    onChangePage(currentPage) {
      var start;
      var end;
      if (currentPage === 0) {
        start = 0;
        end = 26;
      } else {
        start = 25 * currentPage;
        end = start + 26;
      }

      axios({
        url:
          "/?start=" +
          start +
          "&length=" +
          end +
          "&log_id=" +
          filterData.log_id +
          "&status=" +
          filterData.status +
          "&start_time=" +
          filterData.start_time +
          "&end_time=" +
          filterData.end_time +
          "&api_name=" +
          filterData.api_name,
        method: "GET",
        data: {},
      }).then((response) => {
        if (response.status) {
          setLogs(response);
        }
      });
    },

    sortOrder: {
      name: "log_timestamp",
      direction: "desc",
    },
  };

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        options={options}
        data={logs.data}
        title={`Total Logs - ${logs.total_count}`}
      />
    </React.Fragment>
  );
}
