import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Visibility } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";

import { useAxios } from "../../../hooks";
import { convertDate } from "../../../utils";
import { alert } from "../../../redux/slices/alertSlice";
import { Chip, ComponentHeader, DataTable } from "../../../components";

import LogsRequestReports from "../logs request table components/LogsRequestReports";

export default function Table({ logsRequest, apiList, setGenerateLogId }) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [logRequestState, setLogRequestState] = useState({
    state: false,
    data: {},
    link: "",
  });

  const columns = [
    {
      name: "request_id",
      label: "Request ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "request_name",
      label: "Request Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "created_by",
      label: "Created By",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "updated_at",
      label: "Updated At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? (
            value === "Success" ? (
              <Chip type="success" label="Success" />
            ) : value === "Failed" ? (
              <Chip type="error" label="Failed" />
            ) : (
              <Chip type="warning" color="warning" label="In Progress" />
            )
          ) : (
            "Not Available"
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (rowIndex, index) => (
          <div>
            <div style={{ width: "35px" }}>
              <IconButton
                color="primary"
                size="small"
                onClick={() => logsRequestReport(index.rowData[0])}
              >
                <Tooltip title="View Logs Request Detail">
                  <Visibility />
                </Tooltip>
              </IconButton>
            </div>
          </div>
        ),
      },
    },
  ];

  const options = {
    print: false,
    search: false,
    filter: false,
    download: false,
    viewColumns: true,
  };

  const logsRequestReport = (requestId) => {
    axios({
      url: "/view-logs-report?request_id=" + requestId,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        setLogRequestState({
          state: true,
          data: res.data,
        });
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        data={logsRequest}
        title={" Log Report Requests "}
        options={options}
      />

      <LogsRequestReports
        setLogRequestState={setLogRequestState}
        logRequestState={logRequestState}
        logsRequestReport={logsRequestReport}
      />
    </React.Fragment>
  );
}
