import React from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Dialog,
  Tooltip,
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FileCopy, Cancel, Sync, Summarize } from "@mui/icons-material";

import { Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";
import { useAxios, useComponent } from "../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "18px" !important;
  font-weight: "700" !important;
`;

export default function LogsRequestReports({
  logRequestState,
  setLogRequestState,
  logsRequestReport,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(alert.success("Text copied to clipboard successfully!"));
  };

  function downloadFile() {
    axios({
      url: "/download-logs?request_id=" + logRequestState.data?.request_id,
    }).then((response) => {
      if (response.status) {
        window.location.href = response.data.url;
      }
    });
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={logRequestState.state}
      onClose={handleClose}
      fullWidth
    >
      <Grid container spacing={0} style={{ borderBottom: "1px solid #cbcbcb" }}>
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h4">Request Logs Details</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} />

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Request ID
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              {logRequestState.data?.request_id?.slice(0, 15) +
                (logRequestState.data?.request_id?.length > 15 ? "..." : "")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0px 12px" }}
              onClick={() => copyText(logRequestState.data.request_id)}
            >
              <Tooltip title="Copy  Request Log Id">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Request Name
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{logRequestState.data.request_name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Created At
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {convertDate(logRequestState.data.created_at)}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Created By
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{logRequestState.data.created_by}</Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              API Name
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{logRequestState.data.api_name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              API Status
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{logRequestState.data.api_status}</Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              From
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              {convertDate(logRequestState?.data?.filter?.from)}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              To
            </FontSizeTypography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              {convertDate(logRequestState?.data?.filter?.to)}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Status
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            {logRequestState.data?.report_generated ? (
              <Chip type="success" label="Generated" />
            ) : (
              <Chip type="error" label="Not Generated" />
            )}
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Message
            </FontSizeTypography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{logRequestState.data.report_generated_dec}</Typography>
          </Grid>

          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => logsRequestReport(logRequestState.data.request_id)}
          >
            Refresh
          </Button>
        </Grid>

        {logRequestState.data?.report_generated && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadFile}
              startIcon={<Summarize />}
            >
              Download Request Log Report
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setLogRequestState({
      state: false,
      data: {},
    });
  }
}
