const pages = {
  //Dashboard Module
  signIn: {
    ID: "sign-in",
    route: "/sign-in",
  },

  logsPage: {
    ID: "logs",
    route: "/logs",
  },

  logsRequest: {
    ID: "logs-request",
    route: "/logs-request",
  },

  logsRequestDetail: {
    ID: "logsRequestDetail",
    route: "/DownloadCSVDialog",
  },
};

export default pages;
