import axios from "axios";
import { useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import BASE_URL from "../constants/baseURL";
import { alert } from "../redux/slices/alertSlice";
import { apiError } from "../redux/slices/loaderSlice";
import { deleteAccessToken } from "../redux/slices/memberSlice";

export default function useAxios({
  baseURL = "icici_logs",
  customBaseURL = "",
  customAuthToken = "",
  disableAuthToken = false,
} = {}) {
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.memberDetails);

  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      headers: {
        ...(!disableAuthToken && {
          Authorization: "Bearer " + (customAuthToken || authToken),
        }),
        "content-type": "application/json",
      },

      ...(Boolean(customBaseURL)
        ? { baseURL: customBaseURL }
        : {
            baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,
          }),

      validateStatus: (status) => status < 500,
    });
  }, [authToken, baseURL, customAuthToken, customBaseURL, disableAuthToken]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      disableRedirect = false,
      disableError = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
        })
        .then((response) => {
          if (
            response.status === 401 ||
            response.statusText === "Unauthorized"
          ) {
            dispatch(
              alert({
                message: response.data.error,
                type: "error",
              })
            );

            return dispatch(deleteAccessToken());
          }

          if (response.data.detail?.code === "#401") {
            dispatch(
              alert({
                message:
                  "Could not validate your credentials. Please log in again",
                type: "error",
              })
            );
            return dispatch(deleteAccessToken());
          }

          if (response.status > 201 || !response.data.status) {
            if (!disableError) {
              dispatch(
                alert({
                  message:
                    response.data?.message?.displayMessage ||
                    "Error loading data!",
                  type: "error",
                })
              );
            }

            if (!disableRedirect) {
              dispatch(apiError(response.data?.message?.traceID));

              throw new Error();
            } else {
              return { status: false };
            }
          }

          return response.data;
        }),
    [dispatch]
  );
}
